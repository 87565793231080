  .button-area{
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 10px;
    background-color: var(--Green);
    padding: 8px 12px 8px 12px;
  }
  
  .cancel-btn{
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: var(--Generic-White);
    background-color: var(--Green);
    border: none;
    cursor: pointer;
  }
  
  .save-btn{
    padding: 8px 18px 8px 18px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: var(--Generic-White);
    color: var(--Green);
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    cursor: pointer;
  }

  .processing-btn{
    padding: 8px 18px 8px 18px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid transparent;
    background: #cccccc;
    color: var(--Generic-White);  
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    cursor: pointer;
  }