.changing-status-dialog-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-title {
        width: 100%;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: center;
        color: var(--CCA-Maroon);
        text-align: center;
    }

    .dialog-content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 300;
        line-height: 18.4px;
        text-align: left;
    }

    .dialog-cancel-btn {
        padding: 10px 36px;
        gap: 10px;
        border-radius: 50px;
        border: 2px;
        border: 2px solid var(--CCA-Maroon);
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        text-align: left;
        background-color: var(--Generic-White);
        color: var(--CCA-Maroon);
    }

    .disable-assign-button {
        padding: 10px 36px 10px 36px;
        border-radius: 50px;
        border: 2px solid #cccccc;
        color: var(--Generic-White);
        background: #cccccc;
        color: var(--Generic-White);        
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        cursor: pointer;
    }
}