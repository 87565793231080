.eap-cases-sidebar-component {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-family: Inter;
    border: 1px solid var(--CCA-Maroon);
    background-color: var(--Generic-White);
    height: calc(100vh - 92px);
    overflow-y: auto;
    padding: 15px;

    .search-box {
       display: flex;
       width: auto;
       justify-content: space-between;
       align-items: center;
       padding: 5px 10px;
       border: silver 1px solid;
       border-radius: 7px;
       cursor: pointer;

       .search-input-box input{
        outline: none;
        border: none;
        font-size: 14px;
        flex: 1;
        width:100%
       }
    }

    .search-icon{
        display: flex;
        width: auto;
        height: 32px;
        justify-content: end;
    }

    .case-options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        background-color: rgb(198, 198, 198);
        border-radius: 7px;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        align-items: center;
    }

    .active-case-type{
        background-color: var(--CCA-Maroon);
        color: var(--Generic-White);
        border-radius: 7px;
        padding: 5px;
        cursor: pointer;
        text-align: center;
    }

    .case-list{
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .case-title {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: rgb(185, 185, 185);
    }

    .info-section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
     
    .case-div{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #CCD0DE;
        padding: 5px;
        cursor: pointer;

    }

    .active-case-green {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #CCD0DE;
        padding: 5px;
        background-color: var(--Green-Lighter);
        border-radius:8px; 
        cursor: pointer;
    }

    .active-case-red {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #CCD0DE;
        padding: 5px;
        background-color: var(--Maroon-Lighter); 
        border-radius: 8px; 
        cursor: pointer;
    }
    .case-div,
    .active-case-green,
    .active-case-red {
      margin-bottom: 4px; 
      transition: all 0.3s ease-in-out;
    }
    
    .case-id{
        font-size: 14px;
        color: black;
    }

    .date-time-section{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 5px;
    }

    .last-modified {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: var(--neutral-100);
        display: flex;
        justify-content: flex-end;
    }

    .identity-section{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: var(--neutral-100);
    }

    .user-identity{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
    }

    .section-one{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .loader-box{
        margin-top: 100px;
        max-height: max-content;
    }

    .no-cases {
        width: 100%;
        font-family: Inter;
        font-size: 18px;
        line-height: 20.7px;
        text-align: left;
        font-weight: 300;
        color: #6B6F8C;
        text-align: center;
        margin-top: 15px;
    }
}

//CSS class to over-write MUI css classes

#case-list .MuiPaper-elevation{
    top: 120px !important;
    border-radius: 0px 0px 4px 4px;
    scrollbar-width: thin;
    overflow-y: scroll;
    width: 100% !important;
}
.eap-cases-sidebar-component .search-box .search-input-box {
    flex: 1;
}