.loader-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  height: 100%;
  padding: 10px 5px;
}
.loader-component span {
  color: gray;
  font-family: Inter;
  font-size: large;
  font-weight: 500;
}

.loader {
  border: 4px dotted rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 40px; 
  height: 40px; 
  animation: rotate 1.5s linear infinite; 
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
