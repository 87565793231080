.settings-page {
    display: flex;
    width: 100%;
    height: 100%;

    .setting-content {
        padding: 24px 36px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .success {
        margin-top: 15px;
    }

    .setting-component {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .button-margin {
        margin-top: 24px;
    }

    .loader-box {
        display: flex;
        width: 100%;
        margin-top: 60%;
    }

    .searc-box-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .setting-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 32px;
    }

    .setting-info {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .setting-title {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
    }

    .setting-desciption {
        color: var(--text-secondary);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        color:var(--CCA-Maroon)
    }

    .input-box {
        border-radius: 12px;
        border: 1.5px solid #CCD0DE;
        background: var(--Generic-White);
        display: flex;
        padding: 12px 20px;
        outline: none;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        flex: 1;
        margin-right: 12px;
    }

    .margin-input-box {
        margin-top: 32px;
    }

    .add-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        row-gap: 12px;
        border-bottom: 1px solid black;
        padding-bottom: 6px;
        margin-top: 15px;
    }

    .input-area {
        display: flex;
        flex: 1;
        margin-left: 15px;
    }

    .no-data {
        color: var(--text-disabled);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        margin-top: 20px;
    }

    .input-text {
        border: none;
        text-decoration: none;
        background-color: transparent;
        font-size: 16px;
        height: 40px;
        outline: none;
        display: flex;
        align-items: center;
        flex: 1;
    }

    .input-white {
        border: none;
        text-decoration: none;
        background-color: var(--Generic-White);
        font-size: 16px;
        height: auto;
        outline: none;
    }

    .common-action-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 15px;
        padding: 12px;
    }

    .white-action-bar {
        background-color: var(--Generic-White);
    }

    .transperent-bar {
        background-color: transparent;
    }

    .button-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 18px;
    }

    .line-button {
        color: var(--text-primary);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%;
        letter-spacing: 0.4px;
        text-decoration-line: underline;
        cursor: pointer;
        text-transform: uppercase;
    }

    .prt-manager-edit-btn {
        margin-right: 65px;
        margin-left:-30px
    }

    .green-line-button {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        border: 2px solid #71BB9D;
        color: #71BB9D;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background-color: transparent;
    }

    .link-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 15px;
    }

    .link-section-underline .clickable-link {
        text-decoration: underline; 
        text-decoration-color: var(--CCA-Maroon);
        color: black; 
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .title-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .copy-icon {
        width: 11px;
        height: auto;
        margin-right: 5px;
    }

    .data-box {
        display: flex;
        flex: 1;
    }

    .title-margin {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-left: -28%;
    }

    .title-margin-email {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin-left: -12%;
    }
}