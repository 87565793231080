.additional-provider-component {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 12px;

    .provider-title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
        letter-spacing: -0.02em;
        text-align: left;
        width: 25%;
    }

    .disbled-block {
        opacity: 0.5;
    }

    .fields {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        align-items: center;
        width: auto;
        padding-left: 12px;
    }

    .input-box {
        width: 100%;
    }

    .input-box-disabled {
        width: 100%;
    }

    .delete-icon {
        width: auto;
        height: 40px;
        cursor: pointer;
    }

    .provider-action-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .dropdown-section {
        max-width: 300px;
        min-width: 250px;
        width: 100%;
    }

    .buttons-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
    }

    .save-button {
        padding: 8px 36px 8px 36px;
        border-radius: 50px;
        border: 2px solid var(--CCA-Green);
        color: var(--CCA-Green);
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .message-block {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        justify-content: flex-end;
    }
}