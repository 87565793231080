.case-list-component {
  width: 100%;

  .menu-items {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 16px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .info-col {
    display: flex;
    row-gap: 10px;
    justify-content: space-between;
    width: 100%;
  }

  .file-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width:100%;
    flex-direction: column;
  }

  .case-header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .file-name {
    font-family: Inter;
    font-size: 18px;
    font-weight: 300;
    line-height: 20.7px;
    text-align: left;
  }

  .claimed-status {
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    color: var(--CCA-Maroon);
    padding-left: 8px;
    margin-left: auto;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    white-space: break-spaces;
    text-align: right;
  }


  .last-modify-date {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: rgba(156, 156, 156, 0.5);
    display: flex;
    font-family: Inter;
    word-break: break-word;
    white-space: break-spaces;
  }

  .claim-button {
    padding: 4px 18px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.1px;
    text-align: left;
    background-color: var(--CCA-Maroon);
    border: none;
    color: var(--Generic-White);
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .no-cases {
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #6B6F8C;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
  }
}