@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@500&family=Roboto&display=swap");
@import "./colors.css";

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--CCA-Grey);
  padding-top: 50px;
  padding-bottom: 20px;
}

.page-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex: 1 1;
  background: var(--CCA-Grey);
}

.page-content {
  display: flex;
  flex-direction: column;
  background: var(--CCA-Grey);
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  height: 100%;

  @media (min-width: 426px) {
    .page-content {
      padding-top: 50px;
    }
  }
}

.title {
  width: auto;
  color: var(--neutral-800);
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 0 20px;
  @media (min-width: 800px) {
    max-width: 768px;
    font-size: 52px;
    line-height: 1.1;
    margin-top: 12px;
  }
}

.subtitle {
  color: var(--CCA-Maroon);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px 0;
}

.sub-title {
  color: var(--CCA-Brown);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}

.input-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-div label {
  color: var(--neutral-900);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}

.input-div-select {
  height: 35px;
  background-color: white;
  font-family: Inter;
  font-size: 14px;
  border-radius: 2px;

  @media (min-width: 800px) {
    font-size: 16px;
    line-height: 1.2;
  }
}

.nav-link {
  color: var(--CCA-Maroon);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
  padding-bottom: 40px;
}

.large-green-button {
  width: auto;
  padding: 14px 20px;
  border-radius: 8px !important;
  background: var(--CCA-Green);
  color: var(--Generic-White);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  border: none !important;

  @media (min-width: 426px) {
    width: 384px;
  }
}

.large-green-button-disabled {
  width: auto;
  padding: 14px 20px;
  border-radius: 8px !important;
  background: #cccccc;
  color: var(--Generic-White);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: not-allowed;
  border: none !important;

  @media (min-width: 426px) {
    width: 384px;
  }
}

.success {
  font-size: 16px;
  color: rgb(9, 183, 9);
  font-weight: 600;
  font-family: Inter;
}

.question-button-no {
  display: flex;
  width: 75px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none !important;
  color: var(--Generic-White);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  background: var(--CCA-Maroon);
  cursor: pointer;

  @media (min-width: 426px) {
    width: 134px;
  }
}

.question-button-yes {
  display: flex;
  width: 75px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--CCA-Green);
  border: none !important;
  color: var(--Generic-White);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;

  @media (min-width: 426px) {
    width: 134px;
  }
}

.question-button-yes-disable {
  display: flex;
  width: 75px;
  height: 44px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #cccccc;
  border: none !important;
  color: var(--Generic-White);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: not-allowed;

  @media (min-width: 426px) {
    width: 134px;
  }
}

.question-button-div {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  margin: 34px 0px;
}

.view-button {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--CCA-Green);
  color: var(--Generic-White);
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border: none !important;
  cursor: pointer;
  width: 100%;
}

.select-menu {
  width: 300px;
  background-color: var(--Generic-White);
  white-space: normal;
  margin-bottom: 30px;

  @media (min-width: 500px) {
    width: 350px;
  }
}

.error {
  font-size: 0.9em;
  color: #f34;
  text-align: center;
  margin-top: 8px;
  font-weight: 600;
}

.show-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hide-div {
  display: none;
}

.margin-content {
  margin-top: 50px;
}

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: var(--CCA-Grey);
}

.assign-button {
  padding: 10px 36px 10px 36px;
  border-radius: 50px;
  border: 2px solid var(--CCA-Green);
  background-color: var(--CCA-Green);
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
}
.dialog-buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  column-gap: 10px;
  width: 100%;
}

.light-green-button {
  width: max-content;
  padding: 10px 36px;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid #71BB9D;
  background: #71BB9D;
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.add-button {
  width: max-content;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 6px;
}

.large-maroon-button {
  width: auto;
  padding: 14px 20px;
  border-radius: 8px !important;
  background: var(--CCA-Maroon);
  color: var(--Generic-White);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  border: none !important;

  @media (min-width: 426px) {
    width: 384px;
  }
}

.inline-response-message {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-align: start;
}

.success-message{
  color: var(--CCA-Green-Dark);
}

.error-message-red{
  color: var(--CCA-Maroon);
}

@media (max-width: 828px) {
  .emergency-disclaimer-component {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px; 
  }
}

@media (max-width: 500px) {
  .emergency-disclaimer-component {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 426px) {
  .emergency-disclaimer-component {
    font-size: 28px ;
    line-height: 40px ;
  }
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    max-height: 60px !important;
  }
  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    max-height: 60px !important;
  }
}

.backdrop-loader-reopen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
  background-color: var(--Generic-White);
  padding: 20px 40px;
  border-radius: 10px;
  span {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
}
