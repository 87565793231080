.eap-cases-page {
    display: flex;
    width: 100%;
    height: 100%;

    .loader-component-section {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .case-info-section {
        display: grid;
        grid-template-columns: 25% 75%;
        width: 100%;
    }

    .case-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px;
    }

    .case-sub-section {
        display: flex;
        flex-direction: column;
        text-align: start;
        font-size: 14px;
        border-bottom: 1px solid black;
        padding-bottom: 25px;
    }

    .case-staus-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .case-status-content {
        display: flex;
        flex-direction: column;
        text-align: start;
        row-gap: 3px;
    }

    .input-box {
        outline: none;
        border: silver 1px solid;
        font-size: 14px;
        background-color: var(--Generic-White);
        padding: 10px;
        border-radius: 7px;
    }

    .input-box-disabled {
        outline: none;
        border: silver 1px solid;
        font-size: 14px;
        background-color: var(--Generic-White);
        padding: 10px;
        border-radius: 7px;
        opacity: 0.5
    }

    .add-provider-button {
        width: max-content;
        padding: 10px;
        border-radius: 20px;
        text-align: center;
        font-size: 16px;
        border: 1px solid black;
        cursor: pointer;
        margin-top: 15px;
    }

    .select-button {
        width: 100%;
        padding: 5px;
        height: 38px;
        border-radius: 5px;
        border: silver 1px solid;
        background-color: var(--Generic-White);
        outline: none;
    }

    .notes-textarea {
        padding: 10px;
        border-radius: 5px;
        border: silver 1px solid;
        background-color: var(--Generic-White);
        outline: none;
    }

    .no-data-section {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
    }
    .no-additional-provider-section {
        height: 100%;
        width: 100%;
        display: flex;
        font-size: 18px;
        font-weight: 600;
    }
    .save-notes {
        padding: 10px 20px;
        font-size: large;
        font-weight: 600;
        color: var(--Generic-White);
        background-color: var(--CCA-Green);
        display: flex;
        align-self: center;
        border: none;
        border-radius: 7px;
        text-align: center;
    }

    .button-section {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        margin: 25px 0px 0px 0px;
    }

    .success-msg-section {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 15px;
    }

    .success-message {
        text-align: center;
        font-size: 16px;
        color: rgb(9, 183, 9);
        font-weight: 600;
        font-family: Inter;
    }

    .notes-section {
        display: flex;
        flex-direction: column;
        text-align: start;
        font-size: 14px;
        justify-content: center;
        padding-bottom: 25px;
    }

    .details {
        display: flex;
        flex-direction: column;
    }

    .btn-section {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .case-details-section {
        flex: 1;
        overflow-y: auto;
        height: calc(100vh - 60px);
    }
    
    .back-drop {
        background: rgba(255, 255, 255, 0.15);
    }

    .backdrop-loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
        background-color: var(--Generic-White);
        padding: 20px 40px;
        border-radius: 10px;
    }

    .backdrop-loader span {
        font-size: 16px;
        font-weight: 700;
        color: black;
    }
}