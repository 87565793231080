:root {
  --CCA-Grey: #f4f0ed;
  --CCA-Green: #3f7071;
  --CCA-Green-Dark: #2B7272;
  --CCA-Maroon: #631837;
  --Maroon-Lighter: #EDE1DF;
  --Maroon-Light: #6318371A;
  --Generic-White: #fff;
  --Neutral-200: #e5e5e5;
  --neutral-500: #737373;
  --Neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;
  --neutral-100:#16000A;
  --CCA-Brown: #807064;
  --CCA-Light-Brown: #d9c8bb;
  --Green: #71BB9D;
  --Green-Lighter:#7cbb718c;
  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary : rgba(0, 0, 0, 0.60);
  --text-disabled: rgba(0, 0, 0, 0.38)
}