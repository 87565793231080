.onboard-login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;

    .signup-form{
        margin-top: 20px;
    }
  }
  