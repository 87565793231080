.signup-form {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  row-gap: 20px;

  @media (min-width: 385px) {
    width: 384px;
  }

  @media (min-width: 426px) {
    margin-top: 60px;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: 426px) {
    flex-direction: row;
    column-gap: 8px;
  }
}

.signup-page-input {
  font-size: 14px;
  font-style: normal;
  height: 18px;
  padding: 8px 12px;
  width: auto;

  @media (min-width: 426px) {
    align-items: center;
    align-self: stretch;
    display: flex;
    padding: 8px 12px;
    width: 160px;
  }
}

.signup-page-email-input {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  width: auto;
  height: 18px;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;

  @media (min-width: 426px) {
    height: auto;
    font-size: 14px;
  }
}

.check-box-label {
  font-family: Inter;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

.check-box-label input {
  transform: scale(1.3);
}

.check-box-label div {
  display: flex;
  flex-direction: column;

  @media (min-width: 400px) {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }
}

.check-box-label span {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
