.onboarding-coordinator-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;

    .signup-form {
        margin-top: 32px;
    }

    .captcha-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}