.settings-tab-component {
    display: flex;
    width: fit-content;
    flex-direction: column;
    gap: 14px;
    font-family: Inter;
    border: 1px solid var(--CCA-Maroon);
    background-color: var(--Generic-White);
    height: calc(100% - 32px);
    padding: 15px 5px ;

    .active-tab{
        display: flex;
        flex-direction: row;
        border-right: var(--CCA-Green) 3px solid;
        cursor: pointer;
        column-gap: 12px;
        margin-right: 10px;
    }

    .tabs {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        column-gap: 12px;
        margin-right: 10px;
    }
}