.search-box-component {
    display: flex;
    column-gap: 10px;
    padding: 5px 10px;
    border: silver 1px solid;
    border-radius: 7px;
    cursor: pointer;
    background-color: var(--Generic-White);

    input {
     outline: none;
     border: none;
     font-size: 14px;
    }

    .search-icon{
        width: auto;
        height: 32px;
    }
 }

